import { Link } from "react-router-dom";
import BackgroundBasedOnChance from "../lib/background_based_on_chance";
import NameFixer from "../lib/name_fixer";

export function BossCards({monster, chance, lastSeen, categories}){
  let bgColorOnHover= 'hover:bg-sky-600'
  const hasChance = (chance !== undefined)
  let daysPassed = undefined;
  let tooltipText = undefined;
  if (lastSeen) {
    const lastSeenDate = new Date(lastSeen)
    daysPassed = Math.ceil( (new Date() - lastSeenDate) / (1000 * 60 * 60 *24) )
    if (daysPassed > 1) {
      daysPassed = `${daysPassed} days ago`
    } else {
      daysPassed = "yesterday"
    }
    tooltipText = `Last kill registered at ${lastSeenDate.toLocaleDateString()}.`
  }
  if(hasChance){
    bgColorOnHover = BackgroundBasedOnChance(chance)
  }
    return (
    <Link 
    to={`/bosses/${monster.id}`}
    title={tooltipText}
    className={`flex flex-col gap-3 w-52 h-52 text-center border-4 border-black rounded-xl font-semibold ${bgColorOnHover} bg-gray-100 duration-300 text-sm
        hover:scale-110 text-[#0d6efd] hover:text-gray-100`}>
        <img className="w-14 h-16 mx-auto -translate-y-1/2" src={`/images/boss_icons/${NameFixer(monster.name)}.gif`} alt={monster.name}/>
        <h3 className="text-lg mt-2 font-bold">{monster.name}</h3>
      <div>
      {hasChance && 
        <p className="text-black">Chance to spawn: {chance}%</p>
      }
      {daysPassed && 
          <p className="text-black">
            Last killed {daysPassed}.
          </p> 
      }
      {categories &&
          <div className="flex gap-3 mt-2 justify-center">
            {categories.map(tag =>
              <div key={tag}>
                <p>{tag}</p>
              </div> 
            )}
          </div>
      }
      </div>
    </Link>  
  )
}
