import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { BossCards } from "../components/BossCards"
import { PredictionTable } from "../components/PredictionTable"
import 'react-loading-skeleton/dist/skeleton.css'
import colorBasedOnChance from "../lib/color_based_on_chance"
import { FunnelSimple } from 'phosphor-react'

export default function Predictions() {
  const params = useParams()
  const navigate = useNavigate()

  const [serverList, setServerList] = useState([])
  const [selectedServer, setSelectedServer] = useState()
  const [loading, setLoading] = useState(true)
  const [updatedAt, setUpdatedAt] = useState()
  const [predictions, setPredictions] = useState({})
  const [unfilteredPredictions, setUnfilteredPredictions] = useState({})
  const [selectedChance, setSelectedChance] = useState('veryHighChance')
  const [filters, setFilters] = useState([]) 
  const [collapse, setCollapse] = useState(true)
  const availableChances = ['veryHighChance', 'highChance', 'lowChance', 'notExpected']
  const availableTags = ['Valuable', 'Vampire Lord', 'POI', 'Rookgaard']
  const currentServer = selectedServer

  function changeChance(event){
    setSelectedChance(camelize(event.target.innerHTML));
  }
  
  function changeFilters(selectedFilter){
    const newFilters = [...filters]
    if (!filters.includes(selectedFilter)){
      newFilters.push(selectedFilter)
    }else{
      const indexToRemove = filters.indexOf(selectedFilter)
      newFilters.splice(indexToRemove, 1)
    }
    setFilters(newFilters)
  }

  useEffect(()=> {
    const fetchData = async () => {
      const response = await fetch(`https://api.bosswatchtower.com/api/v1/servers`)
      const servers = await response.json()
      setServerList(servers)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setUpdatedAt()
      const response = await fetch(`https://api.bosswatchtower.com/api/v1/servers/${currentServer}/predictions/${selectedChance}`)
      const data = await response.json()
      setPredictions(data.predictions)
      setUnfilteredPredictions(data.predictions)
      if (data.updatedAt) {
        const updatedAt = new Date(Date.parse(data.updatedAt))
        setUpdatedAt(updatedAt.toLocaleString())
      }
      setLoading(false)
    }
    if (!currentServer || currentServer === 'choose a server'){
      return
    }
    fetchData()
  }, [currentServer, selectedChance])

  useEffect(() => {
    if (filters.length > 0){
      const filteredPredictions = {
        [selectedChance]: unfilteredPredictions[selectedChance]?.filter(
          prediction => filters.every(filter => prediction.boss.categories.includes(filter))
        )
      }
      setPredictions(filteredPredictions)
    } else{
      setPredictions(unfilteredPredictions)
    }
  }, [filters, unfilteredPredictions, selectedChance])

  useEffect(() => {
    setCollapse(true)
  }, [selectedServer])

  function fixChanceFormatting(chance) {
    let words = chance.split(/(?=[A-Z])/);
    return words.map(word => word.toLowerCase()).join(' ');
  }

  const selectedServerChanged = (server) => {
    navigate("/predictions/" + server.toLowerCase())
  }

  function camelize(str) {
    return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  function activationStatus(param){
    if (param === selectedChance || filters.includes(param)) {
      return 'active'
    } else {
      return ''
    }
  }

  useEffect(() => {
    setSelectedServer(params.server || 'choose a server')
  }, [params.server])

  return(
    <>
      <section>
        <h1 className='text-5xl font-bold text-center py-10'>Predictions</h1>
        <form className='px-5 mx-auto'>
          <select id="serverSelect" onChange={event => selectedServerChanged(event.target.value.toLowerCase())} 
          className='bg-gray-800 border text-gray-300 border-gray-100 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' 
          name="servers">
            <option>Choose a server</option>
            {serverList.map(server => 
              <option value = {server.name} key={server.id}>
                {server.name}
              </option> )}    
          </select>  
        </form>
      </section>
      { (selectedServer && selectedServer !== 'choose a server') && 
        <>
          <section>
            <div className='px-5 mt-2'>
              {availableChances.map(chance => 
                  <button
                    className={`capitalize mt-2 selector ${activationStatus(chance)}`}
                    onClick={changeChance}
                    key={chance}
                  >
                    {fixChanceFormatting(chance)}
                  </button>  
              )}
            </div>
            <h2 className='text-3xl mt-10 ml-5 font-semibold'>Predictions for <span className='capitalize'>{selectedServer}</span></h2>
            {updatedAt && <h4 className='text-xs mb-10 ml-5'>Last updated at {updatedAt}</h4>}
            <article className='flex flex-col text-lg font-semibold text-white'>
              <section className='ml-5 text-center font-semibold text-black'>
                <div className='flex items-center hover:cursor-pointer gap-1' onClick={() => collapse === true ? setCollapse(false) : setCollapse(true)} >
                  <h2 className='text-2xl'>Filters</h2>
                  <FunnelSimple />
                </div>
                {(collapse === false) &&
                <div className='flex gap-5 mt-2'>
                  {availableTags.map(tag => 
                    <button 
                      className={`capitalize !text-base selector mb-2 ${activationStatus(tag)}`} 
                      key={tag}
                      onClick={() => changeFilters(tag)}>{tag}</button>
                  )}
                </div>
                }
              </section>
              <PredictionTable changeFilters={changeFilters} filters={filters} color={colorBasedOnChance(selectedChance)} title={fixChanceFormatting(selectedChance)} isLoading={loading}>
                {predictions[selectedChance]?.length === 0 && <p className="text-black">There are no monsters with {fixChanceFormatting(selectedChance)} chance to spawn</p>}
                {predictions[selectedChance]?.map(prediction => (
                  <BossCards
                    key={prediction.boss.id}
                    monster={prediction.boss}
                    chance={prediction.probability}
                    lastSeen={prediction.lastSeen}
                    categories={prediction.boss.categories}
                  />
                ))}
              </PredictionTable>  
            </article>
          </section>
        </>
      }
    </>
  )
}
