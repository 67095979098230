/* eslint-disable no-restricted-globals */
import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { ArrowCircleUp } from 'phosphor-react';
import Aside from '../components/Aside';

function showBackToTopButton(){
  const backToTopButton = document.getElementById('backToTopButton')
      if (scrollY > 550) {
        backToTopButton.classList.remove('hidden')
      } else {
        backToTopButton.classList.add('hidden')
      }
}

function backToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
}

export default function Layout() {
  window.addEventListener('scroll', showBackToTopButton);
  return <>
    <header>
      <Navbar />
    </header>
    <Aside />
    <main onScroll={showBackToTopButton} className="page">
      <div className='scroll'>
        <Outlet />
      </div>
    </main>
    <button onClick={backToTop} 
      className='fixed bottom-8 hidden rounded-full bg-black animate-bounce' 
      id='backToTopButton'> 
      <ArrowCircleUp color="#ffda00" weight='fill' size={46} className='rounded-full'/>
    </button>
    <Footer/>
  </>
}