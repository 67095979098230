import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './global.css'
import Layout from './layout/Layout';
import Bosses from './pages/Bosses';
import Boss from './pages/Boss';
import Predictions from './pages/Predictions';

const router = createBrowserRouter([{
  path: '/',
  element: <Layout />,
  children: [
    { index: true, element: <Predictions />},
    { path: '/predictions/:server', element: <Predictions />},
    { path: '/bosses', element: <Bosses />},
    { path:'/bosses/:id', element: <Boss />},
  ],
}]);

function App() {
  return (<RouterProvider router={router} />)
}

export default App;
