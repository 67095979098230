export default function colorBasedOnChance(chance){
  switch (chance) {
    case 'veryHighChance':
      return 'from-green-500'
    case 'highChance':
      return 'from-green-800'
    case 'lowChance':
      return 'from-orange-400'
    case 'notExpected':
      return 'from-red-600'
    default:
      break;
  }
}
