export default function BackgroundBasedOnChance(chance){
    if (chance >= 10){
      return 'hover:bg-green-500'
    }else if (chance > 2){
      return 'hover:bg-green-700'
    }else if (chance <= 2 && chance > 0){
      return 'hover:bg-orange-400'
    }else if (chance === 0){
      return 'hover:bg-red-600'  
    }
  }
  