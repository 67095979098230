import Skeleton from 'react-loading-skeleton';
import {useState} from 'react'
import { X } from 'phosphor-react';

export function PredictionTable(props){
  const [open, setOpen] = useState(true)

  function collapseOnClick(){
    setOpen((currentValue) => !currentValue)
  }

  const skeleton = (
    <div className="px-20 w-full h-40">
      <Skeleton baseColor='#D4D4D4' highlightColor='black' count={4} />
    </div>
  )
    
  return (
    <section>
      <h1 onClick={collapseOnClick} className={`mb-5 bg-gradient-to-r ${props.color} p-2 pl-5 mx-0 px-0 text-3xl mt-2 font-bold cursor-pointer capitalize`} id="collapser">{props.title}</h1>
      <div>
        {props.filters.length > 0 &&
          <div className='flex justify-center'>
            {props.filters.map(filter =>
            <div onClick={() => props.changeFilters(filter)} className='bg-gray-700 flex justify-center items-center rounded-lg mx-5 gap-5 px-3 hover:cursor-pointer' key={filter}>
              <p>
                {filter}
              </p>
              <X onClick={() => props.changeFilters(filter)} color='red' className='hover:cursor-pointer'></X>
            </div>
            )}
          </div>
        }
      </div>
        {props.isLoading && skeleton}
        {!props.isLoading && open && <div className='flex flex-wrap gap-12 p-12 justify-center' id={props.title}>
          {props.children}
        </div>
        }
    </section>
  )
}
