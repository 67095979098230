import { useEffect, useState } from "react"
import NameFixer from "../lib/name_fixer"
import { Link } from "react-router-dom"
import Skeleton from "react-loading-skeleton"

export default function Aside(){
  const emptyPredictions = {predictions: []}
  const [interestingPredictions, setInterestingPredictions] = useState(emptyPredictions)
  const [loading, setLoading] = useState(true)
  const skeleton = (
    <div className="px-10 w-full h-96 pt-10">
      <Skeleton highlightColor='#D6CFC7' count={15} />
    </div>
  )
  useEffect(()=> {
    const fetchData = async () => {
      const response = await fetch('https://api.bosswatchtower.com/api/v1/very_high_chance_predictions')
      const predictionsList = await response.json()
      setLoading(false)
      setInterestingPredictions(predictionsList)
    }
    fetchData()
  }, [])

  return(
    <aside className="fixed mx-12 bg-[#f1efea] border rounded-lg border-black text-white w-[15%] text-center">
      <div className='tableHeader mt-2'>
        <h2 className="text-center text-3xl mb-6 text-zinc-300 z-10">Probable Spawns</h2>
        <img className='tableImg px-2 -z-10' src={"/ornate_header.png"} alt=""></img>
      </div> 
      <div className="carousel">
        {loading ? skeleton : 
        <div className="slides">
          {interestingPredictions.predictions.map(prediction => {
            return(
              <Link
                key={prediction.id} 
                to={`/bosses/${prediction.boss.id}`}>
                  <div className="slide border-b-2 text-black border-black duration-300 hover:bg-green-500">
                    <div className="flex flex-col justify-center items-center">
                      <img width={'25rem'} src={`/images/boss_icons/${NameFixer(prediction.boss.name)}.gif`} alt={prediction.boss.name}></img>
                      <h3 className="text-lg font-bold text-[#0d6efd]">{prediction.boss.name}</h3>
                    </div>
                    <span>{prediction.boss.server}</span>
                    <span>Chance to spawn: {prediction.probability}%</span>
                  </div>
              </Link>
            )
          })}
        </div>
        }
      </div>
    </aside>
  )
}