export function Footer(){
    return(
        <footer className="w-[60vw] text-center bg-black mx-auto p-10 mb-9 flex flex-col items-center text-white text-xs gap-5 max-sm:w-[100vw]">
            <a href="https://www.tibia.com" target="_blank" rel="noreferrer">
                <img className="w-52 h-32" src={'/tibialogo.png'} alt="Tibia Logo"></img>
            </a>
            <p>Tibia is copyrighted by CipSoft GmbH. The official website for Tibia is
                <a className="text-blue-400 hover:underline" href="https://www.tibia.com" target="_blank" rel="noreferrer">&nbsp;tibia.com</a>
            </p>
            <p>© 2023-2024 The Watchtower</p>
        </footer>
    )
}
