import { useParams } from 'react-router-dom'
import {useEffect, useState} from 'react'

import { ColumnChart } from 'react-chartkick'
import { ArrowCircleLeft } from 'phosphor-react';

import NameFixer from "../lib/name_fixer.js";
import ColorBasedOnValue from "../lib/color_based_on_value.js"
import ItemQuantity from "../lib/item_quantity.js"
import StyleBasedOnResist from "../lib/style_based_on_resist.js"

import 'chartkick/chart.js'

const emptyMonsterInfo = {
  id: 0,
  health: 0,
  experience: 0,
  items: [],
  resists: [],
  skills: [],
  name: "",
  respawnStats: {timers: [], counts: []}
}

export default function Boss() {
  const params = useParams() 
  const [monsterInfo, setMonsterInfo] = useState(emptyMonsterInfo)
  const targetId = params.id
  const timers = monsterInfo.respawnStats.timers
  const counts = monsterInfo.respawnStats.counts
  const goBack = () => {
    window.history.back();
  };

  useEffect(()=> {
    const fetchData = async () => {
      const response = await fetch(`https://api.bosswatchtower.com/api/v1/bosses/${targetId}`)
      const bossDetails = await response.json()
      setMonsterInfo(bossDetails)
    }
    fetchData()
  }, [targetId])
  
  return(
    <div className='flex flex-col items-center'>
      <button className='absolute left-0 translate-x-[20vw] p-5 max-md:-translate-x-3'>
        <button onClick={goBack}>
          <ArrowCircleLeft color="#ffda00" weight='fill' size={36} className='bg-black rounded-full max-md:w-11 max-md:h-11'/>
        </button>
      </button>
      <section className='flex flex-col items-center'>
        <h1 className="text-4xl text-center mt-16 font-bold bossTitle border-black mb-4 max-md:text-3xl">{monsterInfo.name}</h1>
        <img className='text-center w-20 h-24 mb-16' src={`/images/boss_icons/${NameFixer(monsterInfo.name)}.gif`} alt={monsterInfo.name}/>
      </section>
      <article className='flex flex-wrap gap-40 items-center p-12'>
        <div className="flex flex-col m-auto">
          <section className="flex flex-col flex-wrap w-[50vw] bg-neutral-100 rounded-lg text-center m-auto p-9 mb-9 border-2 border-black max-md:w-[75vw]">
            <div className='tableHeader -mt-1'>
              <h2 className="text-center text-4xl mb-6 text-zinc-300 z-10">Loot Table</h2>
              <img className='tableImg -z-10' src={"/ornate_header.png"} alt=""></img>
            </div>
            <ul className="flex flex-row flex-wrap gap-5 justify-center align-middle text-left mx-auto">  
            {monsterInfo.items === null &&
              <p className={`text-lg`}>This monster drops no loot</p>
            }
            {monsterInfo.items?.map(item => {
              return (
                <div className="mx-auto flex flex-row flex-wrap gap-5 justify-center align-middle text-left max-md:flex-col" key={item.name}>
                  <div className="flex flex-row gap-3 mb-12 font-[500] max-md:flex-col">
                    <p className="d-flex flex-row text-lg">{ItemQuantity(item.minQuantity, item.maxQuantity)}</p>
                    <p className={`${ColorBasedOnValue(item.maxValue)} text-lg`}>{item.name} {item.dropChance}</p>
                  </div>
                </div>
              )
            })}
            </ul>
          </section>
          <section className="flex flex-wrap justify-between m-auto border-2 border-black bg-neutral-100 w-[50vw] p-12 mt-5 rounded-xl max-md:w-[75vw]">
            <div className='tableHeader -mt-4'>
              <h2 className="text-center text-4xl mb-6 text-zinc-300 z-10">Boss Stats</h2>
              <img className='tableImg -z-10' src={"/ornate_header.png"} alt=""></img>
            </div>    
            <div className='mb-4'>
              <h2 className="font-bold text-3xl max-md:text-2xl">Damage Taken</h2>
              {monsterInfo.resists.sort((a, b) => {return b.resistValue-a.resistValue}).map(resist => {
                return (
                  <div className={`flex border-2 h-8 border-black rounded-lg justify-between items-center font-[600] px-1 ${StyleBasedOnResist(resist.resistValue)} hover:translate-x-1 transition`} key={resist.resist_type}>
                    <div className='flex content-center items-center'>
                      <img className='resistImage' src={`/images/${resist.resistType.trim()}.gif`} alt=""/>
                      <p>{resist.resistType}</p>
                    </div>
                    <p>{resist.resistValue}</p>
                  </div>
                )
              })}
            </div>
            <div className='mb-4'>
              <h2 className="font-bold text-3xl max-md:text-2xl">Abilities</h2>
              {monsterInfo.skills.map(skill => {
                return (
                  <div key={skill.skill}>
                    <p>{skill.skill}</p>
                  </div>
                )
              })}
            </div>
            <div>
              <h2 className="font-bold text-3xl max-md:2xl">Stats</h2>
              <p>Health: {monsterInfo.health}</p>
              <p>Experience: {monsterInfo.experience}</p>
            </div>
          </section>  
          <section className='mt-28'>
            <div className='tableHeader -mt-4'>
              <h2 className="text-center text-4xl mb-6 text-zinc-300 z-10">Statistics</h2>
              <img className='tableImg -z-10' src={"/ornate_header.png"} alt=""></img>
            </div> 
            <ColumnChart width="50vw" loading="Loading..." colors={["#b00"]} label="Times Killed" xtitle="Respawn Timer (in days)" ytitle="Times Killed" data={counts.map((count, index)=> [timers[index], count])}/>
          </section>
        </div>
      </article>
    </div>
  )
};
