import {useEffect, useState} from 'react'
import { BossCards } from '../components/BossCards.js'

export default function Bosses() {
  const [monsterList, setMonsters] = useState([])
  const [filteredMonsters, setFilteredMonsters] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  
  useEffect(()=> {
    const fetchData = async () => {
      const response = await fetch('https://api.bosswatchtower.com/api/v1/bosses')
      const bossesList = await response.json()
      setMonsters(bossesList)
      setFilteredMonsters(bossesList)
    }
    fetchData()
  }, [])

  useEffect(() => {
    setMonsters(() => filteredMonsters.filter((monster => monster.name.toLowerCase().includes(searchQuery.toLowerCase()))))
  }, [searchQuery])

  return (
    <div className='font-bold'> 
      <div className='flex flex-col items-center justify-center'> 
        <h1 className='text-7xl text-center pt-10 max-md:text-5xl'>Bosses</h1>
        <label className='hidden' htmlFor='bossSearch'>Search for a boss</label>
        <input autoComplete="off" className='px-2 py-1 rounded-md w-96 mt-5 border-2 bg-gray-800 text-gray-300' type='text' onChange={(event) => setSearchQuery(event.target.value)} id='bossSearch' placeholder='Search for a boss...'></input>
        {searchQuery !== '' &&
          <p className='mt-6'>Found {monsterList.length} {monsterList.length === 1 ? 'result' : 'results'} for: "{searchQuery}"</p>
        }
      </div>
      <div className='flex flex-wrap gap-40 mt-10 items-center p-12 justify-center'>
        {monsterList.map(monster => <BossCards key={monster.id} monster={monster} />)}
      </div>
    </div>
  )
};
