import { NavLink, useLocation } from 'react-router-dom';

export function Navbar() {
  const location = useLocation()

  const getLinkClassnames = ({ isActive }) => {
    if (isActive) {
      return 'navlink active';
    }
    return 'navlink';
  }

  const isPredictions = () => {
    if (location.pathname.includes('predictions') || location.pathname === '/') {
      return 'navlink active';
    }
    return 'navlink';
  }

  return ( 
    <nav className='mx-auto font-bold text-white rounded-t-lg'>
      <ul className="navbar">
        <h1 className='title absolute text-4xl'>The Watchtower</h1>
        <div className='navDecorationContainer relative'>
          <img className='navDecoration absolute' src={"/Alert.png"} alt=""></img>
        </div>
        <li className="flex hover:text-[#ffda00] duration-300 mx-10 text-2xl">
          <NavLink className={isPredictions} to=''>Predictions</NavLink>
        </li>
        <li className="flex hover:text-[#ffda00] duration-300 mx-10 text-2xl">
          <NavLink className={getLinkClassnames} to='bosses'>Bosses</NavLink>
        </li>
      </ul>
    </nav>
  )
}
